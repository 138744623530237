import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import axios from "axios";
import { HashRouter } from "react-router-dom";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";

//  TODO REMOVE this console and the hard coding

const link = createUploadLink({
  uri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/graphql"
      : "https://flanna-api-mhq3g.ondigitalocean.app/graphql",

  fetch: buildAxiosFetch(axios, (config, input, init) => ({
    ...config,
    // @ts-ignore # We define onUploadProgress on the mutation - see ReportHome.
    onUploadProgress: init?.onUploadProgress,
  })),
});

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  headers: { mode: "no-cors" },
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
