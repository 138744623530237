export default function TextInput({
  value,
  onChange,
  placeholder,
}: {
  placeholder: string;
  value: string;
  onChange: (arg0: string) => void;
}) {
  return (
    <div className='my-2'>
      <input
        type='text'
        id='comments'
        name='comments'
        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </div>
  );
}
