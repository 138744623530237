import { Report } from "graphql/generated";
import Tag from "components/Tag/Index";
import { gql } from "@apollo/client";

type Props = {
  report: Report;
};

export default function Row({ report }: Props): JSX.Element {
  return (
    <li className='w-full mb-4 bg-white shadow-lg p-3 rounded'>
      <div className='space-y-2'>
        <div className='aspect-w-3 aspect-h-2 mb-4'>
          <img className='object-cover shadow-lg rounded-lg' src={report.photo.url} alt='' />
        </div>
        <div className='text-lg leading-6 text-left font-medium'>
          <h3>{report.title}</h3>
        </div>
        <div className='text-md text-left'>
          <p className='text-gray-500'>{`${String.fromCodePoint(128205)} ${report.addressLabel}`}</p>
        </div>
        <div className='text-md text-left'>
          <p className='text-sm font-small '>{report.comment}</p>
        </div>

        <ul className='flex pb-2 flex-wrap '>
          {report.tags.map((tag, i) => (
            <li key={i}>
              <Tag tag={tag.name} />
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
}

Row.fragments = {
  report: gql`
    fragment rowReport on Report {
      id
      creator
      createdAt
      comment
      addressLabel
      title
      tags {
        id
        name
      }
      photo {
        url
      }
    }
  `,
};
