import React, { useEffect, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { useJsApiLoader } from "@react-google-maps/api";
import { LocationMarkerIcon, CheckCircleIcon } from "@heroicons/react/solid";

import { libraries } from "../Map";
import InputWithButton from "../InputWithButton";

type Props = {
  onAddressSearch: (arg0: google.maps.places.PlaceResult) => void;
  onUserLocationSearch: (arg0: string) => void;
  userLocationPermission: boolean;
  mapLoading: boolean;
  defaultAddress: string | null;
};

export default function AutoCompleteInput({
  onAddressSearch,
  userLocationPermission,
  mapLoading,
  onUserLocationSearch,
  defaultAddress = "Enter Address",
}: Props) {
  const [placeFound, setPlaceFound] = useState(false);

  useEffect(() => {
    setPlaceFound(defaultAddress !== "Enter Address" && defaultAddress !== null);
  }, [defaultAddress]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API as string,
    libraries: libraries,
  });

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace() as google.maps.places.PlaceResult;
      if (place) {
        onAddressSearch(place);
        setPlaceFound(true);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = React.useRef(null);

  const onLoad = React.useCallback(function callback(autocomplete) {
    setAutocomplete(autocomplete);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setAutocomplete(null);
    setPlaceFound(false);
  }, []);

  return isLoaded ? (
    <Autocomplete
      restrictions={{ country: "aus" }}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      onUnmount={onUnmount}
    >
      {/* TODO - Display user address when they locate themselves */}
      <InputWithButton
        leftIcon={
          placeFound ? <CheckCircleIcon className='h-5 w-5 text-gray-400' aria-hidden='true' fill='green' /> : null
        }
        inputRef={inputRef}
        disabled={mapLoading}
        onClick={onUserLocationSearch}
        placeholder={defaultAddress ? defaultAddress : "Enter Address"}
        buttonIcon={
          mapLoading ? (
            <LoadingSpinner />
          ) : (
            <LocationMarkerIcon
              className={`h-5 w-5 text-gray-400 ${userLocationPermission ? "" : "animate-pulse"}`}
              aria-hidden='true'
              fill={userLocationPermission ? "green" : "blue"}
            />
          )
        }
      />
    </Autocomplete>
  ) : (
    <svg className='animate-spin h-5 w-5 mr-3 ...' viewBox='0 0 24 24'></svg>
  );
}

function LoadingSpinner(): JSX.Element {
  return (
    <div className='flex justify-center items-center'>
      <div
        className='spinner-border text-black animate-spin inline-block w-4 h-4 border-4 rounded-full'
        role='status'
      ></div>
    </div>
  );
}
