import { Link } from "react-router-dom";

export default function BrandTitle(): JSX.Element {
  return (
    <Link role='button' to='/' className='w-full '>
      <div className={`bg-grey-50 shadow-lg shadow-slate-300 py-4`}>
        <h1 className='font-fraunces text-4xl'>Beauty Plaza</h1>
      </div>
    </Link>
  );
}
