import { Link } from "react-router-dom";
import BrandTitle from "../BrandTitle";

export default function HomeScreen() {
  return (
    <div className='bg-grey-50 h-full w-screen'>
      <BrandTitle />
      <div className='mt-2 max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 lg:py-16 flex-1 flex-col'>
        <div className='md:m-auto md:w-1/2 justify-center align-middle text-slate-600  text-2xl mx-8 mt-6 '>
          <p className=''>Collect beautiful places.</p>
          <br />
          <p className=''>Help us document the world’s best traditional urbanism and architecture.</p>
        </div>
        <div className='mt-10 text-white'>
          <div className='mb-2 text-white'>
            <Link
              className=' w-2/3 sm:w-1/3  inline-flex items-center text-xl justify-center px-5 py-3 border-transparent rounded-md bg-themeGrey-500'
              to='/feed'
              style={{ color: "white" }}
            >
              View Feed
            </Link>
          </div>
          <div className='mb-10'>
            <Link
              style={{ color: "white" }}
              to='/reportForm'
              className='w-2/3 sm:w-1/3  inline-flex items-center text-xl justify-center px-5 py-3  border-transparent rounded-md bg-themeGreen-500'
            >
              Get started
            </Link>
          </div>
          <img className='mx-auto shrink basis-1/5 object-scale-down w-2/3 md:w-1/3' src='home_image.png' alt='' />
        </div>
      </div>
    </div>
  );
}
