import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { gql } from "@apollo/client";
import { Report } from "graphql/generated";
import { Marker } from "@react-google-maps/api";

type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];

export const libraries: Libraries = ["places"];

type Coordinates = {
  lat: number;
  lng: number;
};

type Props = {
  center: Coordinates;
  onAddressChange: (arg0: google.maps.LatLng) => void;
  zoom: number;
  reports?: Report[];
  full?: boolean;
  onMarkerClick?: (arg0: string, arg1: google.maps.LatLng | null) => void;
};

const Heart = {
  path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
  fillColor: "yellow",
  fillOpacity: 0.9,
  scale: 2,
  strokeColor: "gold",
  strokeWeight: 4,
};

function Map({ center, full, onAddressChange, onMarkerClick, zoom, reports }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API as string,
    libraries: libraries,
  });

  const containerStyle = {
    width: "100%",
    height: full ? "80vh" : "55vh",
  };

  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
    },
    [center],
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function handleMarkerClick(id: string, latLng: google.maps.LatLng | null) {
    if (onMarkerClick == null) return;
    onMarkerClick(id, latLng);
  }

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        // center={center}
        options={{
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER, // 'right-center' ,
          },
          disableDefaultUI: true,
        }}
        // TODO - might need to add this back in for web
        // zoom={5 || 1}
        onLoad={onLoad}
        onUnmount={onUnmount}
        clickableIcons
      >
        {reports != null &&
          reports.map(({ coordinates, id }) => (
            <Marker
              onClick={(e: google.maps.MapMouseEvent) => handleMarkerClick(id, e.latLng)}
              key={id}
              position={{ lat: coordinates.lat, lng: coordinates.long }}
              icon={Heart}
            />
          ))}
      </GoogleMap>
    </div>
  ) : (
    <svg className='animate-spin h-5 w-5 mr-3 ...' viewBox='0 0 24 24'></svg>
  );
}

export default Map;

Map.fragments = {
  mapReports: gql`
    fragment mapReports on ReportConnection {
      edges {
        node {
          id
          coordinates {
            lat
            long
          }
        }
      }
    }
  `,
};
