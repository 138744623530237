import { Tag as TagType } from "graphql/generated";
import { classNames } from "utilis/classnames";

type Props = {
  onRemove?: (name: string) => void;
  tag: string;
};

export default function Tag({ onRemove, tag }: Props): JSX.Element {
  const removable = onRemove !== undefined;
  return (
    <div className='bg-indigo-100 inline-flex items-center text-sm rounded mb-2 mr-2 p-1'>
      <span className={classNames("ml-1 mr-2 leading-relaxed truncate max-w-xs", !removable && "w-20")}>{tag}</span>

      {removable && (
        <button
          onClick={() => onRemove(tag)}
          value={tag}
          className='w-6 h-8 inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none'
        >
          <svg className='w-6 h-6 fill-current mx-auto' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path
              fillRule='evenodd'
              d='M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z'
            />
          </svg>
        </button>
      )}
    </div>
  );
}
