import Alert from ".";

type Props = {
  onAffirm: () => void;
  onCancel: () => void;
  open: boolean;
};

export default function GeoPermissionAlert({ onAffirm, onCancel, open }: Props): JSX.Element {
  return (
    <Alert
      title='We are going to ask for location permissions'
      detail='We need this to tag the photos with the right geolocation.'
      onAffirm={onAffirm}
      onCancel={onCancel}
      open={open}
    />
  );
}
