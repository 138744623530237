export default function TextArea({
  value,
  onChange,
  placeholder,
}: {
  placeholder: string;
  value: string;
  onChange: (arg0: string) => void;
}) {
  return (
    <div className='mt-2'>
      <textarea
        id='comments'
        name='comments'
        rows={3}
        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border border-gray-300 rounded-md'
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </div>
  );
}
