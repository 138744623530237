import React from "react";
import "./App.css";

import HomeScreen from "./components/HomeScreen/Index";
import FeedRoute from "./routes/feed";
import ReportForm from "./routes/reportForm";
import ReportShow from "./routes/reportShow";

import { Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <div className='App flex w-full min-h-screen text-black bg-slate-100 content-center'>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/feed' element={<FeedRoute />} />
        <Route path='/reportForm' element={<ReportForm />} />
        <Route path='report/:id' element={<ReportShow />} />
      </Routes>
    </div>
  );
}
