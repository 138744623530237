type Props = {
  images: FileList;
  onDelete: () => void;
};

export default function PhotoContainer({ images, onDelete }: Props): JSX.Element {
  function imageURL() {
    const image = images[0];

    return URL.createObjectURL(image);
  }

  return (
    <div className='w-full space-y-4'>
      <div className='aspect-w-3 aspect-h-2'>
        <img className='object-cover shadow-lg rounded-lg' src={imageURL()} alt='' />
      </div>

      <div className='space-y-2'>
        <button onClick={onDelete} className='text-gray-400 hover:text-gray-500'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
