import { gql, useQuery } from "@apollo/client";
import { useLayoutEffect, useState } from "react";
import { GetReportsQuery, Report } from "graphql/generated";
import BrandTitle from "components/BrandTitle";
import Row from "./row";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation } from "react-router-dom";
import Header from "./header";
import Map from "../Map";

const GET_REPORTS = gql`
  query GetReports {
    reports(first: 25) {
      ...mapReports
      edges {
        node {
          ...rowReport
          id
          coordinates {
            lat
            long
          }
        }
      }
    }
  }
  ${Row.fragments.report}
  ${Map.fragments.mapReports}
`;

const DEFAULT_CENTER = { lat: -37.8136, lng: 144.9631 };
const DEFAULT_ZOOM = 0;
interface LocationState {
  newReport: boolean;
}

export default function FeedContainer(): JSX.Element {
  const { loading, error, data } = useQuery<GetReportsQuery>(GET_REPORTS, { fetchPolicy: "cache-and-network" });
  const location = useLocation();
  const state = location.state as LocationState;
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(true);
  const [mapEnabled, setMapEnabled] = useState(false);

  useLayoutEffect(() => {
    if (state?.newReport && showToast) {
      toast.info(" Posted!", {
        position: "bottom-center",
        className: "w-1/2 mx-auto",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setShowToast(false);
  }, [showToast, state?.newReport]);
  // TODO - Add something here.
  if (loading) return <p>"Fetching..."</p>;
  if (error) return <p>`Error! ${error.message}`</p>;
  if (data == null) {
    return <p>NO REPORTS COMPONENT GOES HERE</p>;
  }
  if (data?.reports == null || data.reports.edges == null) return <p>NO REPORTS COMPONENT FIRS</p>;

  const reports = data.reports.edges.map((edge) => edge?.node).filter(Boolean) as Report[];

  if (reports.length <= 0) return <p>NO REPORTS COMPONENT SECOND</p>;

  const {
    coordinates: { lat, long },
  } = reports[0];

  const selectedReport = reports.find((report) => report.id === selectedReportId);

  function renderSelectedReport() {
    if (selectedReport == null) return null;
    return <Row report={selectedReport} />;
  }

  function handleMarkerClick(id: string, latLng: google.maps.LatLng | null) {
    //TODO -  Center on icon. Figure out the interactions with loading the component in the first place
    // set selected at
    setSelectedReportId(id);
  }

  return (
    <div className='w-full bg-grey-50'>
      <BrandTitle />
      <ToastContainer
        className='w-1/3'
        position='bottom-center'
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='w-full sm:w-1/2 sm:mx-auto'>
        <Header
          onMap={() => setMapEnabled(!mapEnabled)}
          onFeed={() => setMapEnabled(false)}
          current={mapEnabled ? "Map" : "Feed"}
        />
        {mapEnabled && (
          <div className='rounded h-72'>
            <Map
              onMarkerClick={handleMarkerClick}
              zoom={DEFAULT_ZOOM}
              onAddressChange={() => {}}
              center={state?.newReport ? { lat, lng: long } : { ...DEFAULT_CENTER }}
              reports={reports}
            />
            {renderSelectedReport()}
          </div>
        )}

        {!mapEnabled && (
          <div className='mx-4 sm:mx-auto'>
            <ul className='my-2 pb-10 divide-y divide-gray-200'>
              {reports.map((report, index) => (report == null ? null : <Row key={index} report={report} />))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
