import { useState } from "react";

type Props = Readonly<{
  placeholder: string;
  buttonText?: string;
  leftIcon?: JSX.Element | null;
  buttonIcon?: JSX.Element;
  onClick: (arg0: string) => void;
  inputRef: React.MutableRefObject<null>;
  disabled: boolean;
}>;

export default function InputWithButton({
  placeholder,
  leftIcon,
  buttonIcon,
  buttonText,
  onClick,
  inputRef,
  disabled,
}: Props): JSX.Element {
  const [text, setText] = useState("");

  return (
    <div>
      <div className='mt-1 flex rounded-md shadow-sm'>
        <div className='relative flex items-stretch flex-grow focus-within:z-10'>
          {leftIcon && (
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>{leftIcon}</div>
          )}
          <input
            disabled={disabled}
            type='text'
            name='input'
            id='input'
            ref={inputRef}
            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300'
            placeholder={disabled ? "Searching..." : placeholder}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <button
          onClick={() => onClick(text)}
          type='button'
          className='-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
        >
          {buttonIcon != null && buttonIcon}
          {buttonText && <span>{buttonText}</span>}
        </button>
      </div>
    </div>
  );
}
