import { classNames } from "utilis/classnames";

type Props = {
  onMap: (arg0: React.MouseEvent<HTMLButtonElement>) => void;
  onFeed: (arg0: React.MouseEvent<HTMLButtonElement>) => void;
  current: "Map" | "Feed";
};

export default function Header({ onMap, onFeed, current }: Props): JSX.Element {
  const tabs = [
    { name: "Map", onChange: onMap },
    { name: "Feed", onChange: onFeed },
  ];

  return (
    <div className='my-5 mb-4 sm:mx-2 mx-4  flex'>
      <div className='flex space-x-4 w-full'>
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={tab.onChange}
            className={classNames(
              tab.name === current ? "bg-bg-grey-50 text-gray-800" : "text-gray-600 hover:text-bg-grey-50 ",
              "py-3 rounded-lg font-medium w-1/2  justify-center px-2 border border-transparent shadow-md text-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-grey-50'",
            )}
            aria-current={tab.name === current ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
}
