import { gql, useQuery } from "@apollo/client";
import { GetTagsQuery } from "graphql/generated";

type Props = {
  onAdd: (tag: string) => void;
  currentText: string;
};

// TODO Potentially should extract this shit out into a fragment
const GET_TAGS = gql`
  query GetTags($query: String!) {
    tags(first: 5, query: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function TagSuggestion({ currentText, onAdd }: Props) {
  const { loading, error, data } = useQuery<GetTagsQuery>(GET_TAGS, { variables: { query: currentText } });
  if (currentText === "") return null;

  const tags = data?.tags.edges
    ?.map((edge) => edge?.node)
    .filter(Boolean)
    .filter((node) => node?.name !== currentText); // Filter out current text

  function handleAdd(e: React.MouseEvent<HTMLButtonElement>, tag: string) {
    e.stopPropagation();
    onAdd(tag);
  }

  if (error) return <p>`Error! ${error.message}`</p>;
  if (loading) return null;

  return (
    <div className='relative'>
      <ul className='z-10 absolute top-0 w-full'>
        <Row name={currentText} onClick={handleAdd} />
        {tags != null &&
          tags.map((tag) => (tag != null ? <Row name={tag?.name} key={tag?.id} onClick={handleAdd} /> : null))}
      </ul>
    </div>
  );
}

type RowProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  name: string;
};

function Row({ onClick, name }: RowProps): JSX.Element {
  return (
    <div className='py-1 text-sm bg-white rounded shadow-lg border border-gray-300'>
      <button
        id='add-new-tag-dropdown'
        onClick={(e) => onClick(e, name)}
        className='block w-full py-1 px-5 cursor-pointer hover:bg-indigo-600 hover:text-white'
      >
        Add tag "<span className='font-semibold'>{name}</span>"
      </button>
    </div>
  );
}
