import Tag from "components/Tag/Index";

type SelectedTagsProps = {
  selectedTags: string[];
  onRemove: (tag: string) => void;
};

export default function SelectedTags({ selectedTags, onRemove }: SelectedTagsProps) {
  function handleRemove(tagName: string) {
    onRemove(tagName);
  }

  return (
    <div className='my-2'>
      {selectedTags.map((tag, i) => (
        <Tag tag={tag} key={i} onRemove={handleRemove} />
      ))}
    </div>
  );
}
