type Props = {
  onChange: (arg0: FileList) => void;
};

export default function PhotoUpload({ onChange }: Props): JSX.Element {
  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    if (e.currentTarget.files == null) {
      return;
    }

    onChange(e.currentTarget.files);
  }

  return (
    <div className='w-full flex mt-2 bg-white rounded'>
      <div className='w-full rounded-lg shadow-xl bg-white '>
        <div className='m-4'>
          <div className='flex items-center justify-center w-full'>
            <label className='flex flex-col w-full h-32 border-2 border-dashed hover:bg-gray-100 hover:border-gray-300'>
              <div className='flex flex-col items-center justify-center pt-7'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-12 h-12 text-gray-400 group-hover:text-gray-600'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z'
                    clipRule='evenodd'
                  />
                </svg>
                <p className='pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600'>Select a photo</p>
              </div>
              <input type='file' accept='image/jpeg,image/png' className='opacity-0' onChange={handleChange} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
