import { useState } from "react";

import AutoCompleteInput from "../AutoCompleteInput";
import BrandTitle from "../BrandTitle";
import Map from "../Map";
import TextInput from "./TextInput";
import TextArea from "./TextArea";
import PhotoUpload from "./PhotoUpload";
import PhotoContainer from "./PhotoContainer";
import { gql, useMutation } from "@apollo/client";
import GeoPermissionAlert from "../Alert/GeoPermissionAlert";
import LoadingBar from "components/LoadingBar";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SelectedTags from "./SelectedTags";
import TagSuggestion from "./TagSuggestion";

const defaultCenter = { lat: -37.83860098908794, lng: 144.97697632492282 };

export default function ReportForm() {
  const [geoPermissionsModalOpen, setGeoPermissionsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(defaultCenter);
  const [zoom, setZoom] = useState(0);
  const [mapLoading, setMapLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [title, setTitle] = useState("");

  const [tags, setTags] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState("");
  const [images, setImages] = useState<FileList | null>(null);
  const [userLocationSelected, setUserLocationSelected] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  function handleErrors(errors: any) {
    console.log(errors);
    toast.info("Something went wrong", {
      position: "bottom-center",
      className: "w-1/2 mx-auto",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const CREATE_REPORT = gql`
    mutation CreateReport($input: CreateReportInput!) {
      createReport(input: $input) {
        report {
          id
        }
      }
    }
  `;
  const [createReport, { data, loading, error }] = useMutation(CREATE_REPORT, {
    onError(errors) {
      handleErrors(errors);
    },
    onCompleted({ createReport: { report } }) {
      setSubmitting(false);

      if (report) {
        navigate("/feed", {
          state: {
            newReport: true,
          },
        });
      }
    },
  });

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setSubmitting(true);
    const photo = images?.[0];

    if (photo === null) {
      console.log("photo is cooked");
      return;
    }

    createReport({
      context: {
        fetchOptions: {
          onUploadProgress: (progress: ProgressEvent) => {
            console.log("length computable", progress.lengthComputable);
            console.log("progress loaded", progress.loaded);
            console.log("progress total", progress.total);
            console.log({ progress });
            if (progress.lengthComputable) {
              setUploadProgress(Math.round(progress.loaded / progress.total));
            }
          },
        },
      },
      variables: {
        input: {
          tags: tags,
          comment,
          lat: coordinates.lat,
          long: coordinates.lng,
          photo: photo,
          title: title,
        },
      },
    });
  }

  function handleAddressSearch({ geometry }: google.maps.places.PlaceResult) {
    if (geometry) {
      const { location } = geometry;
      if (location) {
        setCoordinates({ lat: location.lat(), lng: location.lng() });
        setZoom(0);

        setUserLocationSelected(false);
      }
    }
  }

  function updateMapWithUserLocation({ coords }: GeolocationPosition) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat: coords.latitude, lng: coords.longitude } }, (results) => {
      if (results != null) {
        const bestMatch = results[0];
        setAddress(bestMatch.formatted_address);
      }
      setCoordinates({ lat: coords.latitude, lng: coords.longitude });
      setMapLoading(false);
      setZoom(0);
    });
  }

  function handleAddressChange({ lat, lng }: google.maps.LatLng) {
    setCoordinates({ lat: lat(), lng: lng() });
  }

  function handleRemoveTag(tag: string) {
    const newTags = tags.filter((t) => t !== tag);
    setTags([...newTags]);
  }

  function handleAddTag(tag: string) {
    setTags([...tags, tag]);
    setTagInputText("");
  }

  function handleTagInput(tag: string) {
    setTagInputText(tag);
  }

  function handleUserSearch() {
    setMapLoading(true);

    const options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
    };

    try {
      if ("geolocation" in navigator) {
        setAddress(null);
        setUserLocationSelected(true);
        navigator.geolocation.getCurrentPosition(updateMapWithUserLocation, null, options);
      } else {
        setGeoPermissionsModalOpen(true);
      }
    } catch (error) {
      console.log("location permissions error", error);
      setMapLoading(false);
    }
  }

  return (
    <>
      <GeoPermissionAlert
        onAffirm={() => {
          navigator.geolocation.getCurrentPosition((s) => console.log(s));
        }}
        onCancel={() => setGeoPermissionsModalOpen(false)}
        open={geoPermissionsModalOpen}
      />
      <ToastContainer
        className='w-1/3'
        position='bottom-center'
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form className='w-full mb-8'>
        <BrandTitle />
        <div className='mt-4 items-center sm:w-2/3 sm:mx-auto space-y-4 px-4 md:grid md:grid-cols-4 md:gap-8"'>
          <div className='md:col-span-2 sm:mr-4 items-stretch'>
            {/* TODO - do we want to do anything on the report map if you click a marker */}
            <Map onMarkerClick={() => {}} center={coordinates} zoom={zoom} onAddressChange={handleAddressChange} />
          </div>
          <div className='mt-5 md:mt-0 md:col-span-2 '>
            <AutoCompleteInput
              mapLoading={mapLoading}
              onAddressSearch={handleAddressSearch}
              onUserLocationSearch={handleUserSearch}
              userLocationPermission={userLocationSelected}
              defaultAddress={address}
            />
            <TextInput value={title} onChange={(v: string) => setTitle(v)} placeholder='Give your post a title' />
            <TextArea value={comment} onChange={(v: string) => setComment(v)} placeholder='Add a comment' />

            <TextInput value={tagInputText} onChange={handleTagInput} placeholder='Enter some tags' />
            {tagInputText && <TagSuggestion currentText={tagInputText} onAdd={handleAddTag} />}
            <SelectedTags selectedTags={tags} onRemove={handleRemoveTag} />
            {images == null && <PhotoUpload onChange={(files: FileList) => setImages(files)} />}
            {images && <PhotoContainer images={images} onDelete={() => setImages(null)} />}
            {submitting && <LoadingBar percentage={uploadProgress} />}
            <div className='pt-5'>
              <div className='flex justify-center'>
                <Link to='/feed' className='w-1/2'>
                  <button
                    type='button'
                    className=' w-full bg-white py-3 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    Cancel
                  </button>
                </Link>

                <button
                  disabled={images == null || loading}
                  onClick={handleSubmit}
                  type='submit'
                  className='w-1/2 ml-3 py-3 inline-flex bg-themeGreen-500 disabled:border-red-500 disabled:bg-red-400 disabled:text-white justify-center px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-grey-50'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
