/* This example requires Tailwind CSS v2.0+ */

type Props = {
  percentage: number;
};

export default function LoadingBar({ percentage }: Props) {
  return (
    <div className='mx-auto px-2 rounded mt-4 w-full'>
      <progress
        className='transition-all ease-out duration-1000 w-full progress progress-info'
        value={percentage}
        max='100'
      />
      Submitting...
    </div>
  );
}
