import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GetReportsQuery, Report } from "graphql/generated.js";

const GET_REPORT = gql`
  query GetReport($id: ID!) {
    report(id: $id) {
      id
      coordinates {
        lat
        long
      }
    }
  }
`;

export default function ReportShow(): JSX.Element {
  let { id } = useParams();
  // WTF do I do here - String != ID so it wont be accepted

  const { loading, error, data } = useQuery<GetReportsQuery>(GET_REPORT, {
    variables: { id: id as Report["id"] },
  });

  if (loading) return <p>"Fetching..."</p>;
  if (error) return <p>`Error! ${error.message}`</p>;

  if (data == null) {
    return <p>NO REPORTS COMPONENT GOES HERE</p>;
  }

  return (
    <div>
      <p>THIS WORKS</p>
    </div>
  );
}
